import novelaTheme from '@narative/gatsby-theme-novela/src/gatsby-plugin-theme-ui';

export default {
    ...novelaTheme,

    useColorSchemeMediaQuery: true,
    useLocalStorage: false,     // TODO: make this work (use newer theme-ui version?)

    colors: {
        ...novelaTheme.colors,
        primary: "#000",
        secondary: "#73737d",
        accent: "#00a2cf",
        hover: "rgba(0, 0, 0, 0.07)",
        grey: "#73737d",
        background: "#fff",

        modes: {
            dark: {
                primary: "#fff",
                secondary: "#fff",
                accent: "#00a2cf",
                hover: "rgba(255, 255, 255, 0.07)",
                grey: "#73737d",
                background: "#111216",
                gradient:
                    "linear-gradient(180deg, #111216 0%, rgba(66, 81, 98, 0.36) 100%)",
                articleText: "#fff",
                track: "rgba(255, 255, 255, 0.3)",
                progress: "#fff",
                card: "#1D2128",
                error: "#EE565B",
                success: "#46B17B",
                errorBackground: "rgba(238, 86, 91, 0.1)",
                horizontalRule: "rgba(255, 255, 255, 0.15)",
                inputBackground: "rgba(255, 255, 255, 0.07)",
            },
        },
    },
};
